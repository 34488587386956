import * as React from 'react'
import PortfolioItemWrapper from './PortfolioItemWrapper'
import PortfolioItemTitle from './PortfolioItemTitle'
import PortfolioItemImageWrapper from './PortfolioItemImageWrapper'
import PortfolioItemImageContent from './PortfolioItemImageContent'
import PortfolioItemImageCursor from './PortfolioItemImageCursor'
import PortfolioItemImage from './PortfolioItemImage'

export default function PortfolioItem({ el, url }) {
  const [mouseIn, setMouseIn] = React.useState(false)
  const [playAnimation, setPlayAnimation] = React.useState(false)
  const [animation, setAnimation] = React.useState(true)
  const [cords, setCords] = React.useState({ top: 0, left: 0 })
  const [firstTimeout, setFirstTimeout] = React.useState(null)
  const [secondTimeout, setSecondTimeout] = React.useState(null)
  const setCordsHandler = (evt) => {
    setCords({ top: evt.nativeEvent.layerY, left: evt.nativeEvent.layerX })
  }
  const mouseLeaveHandler = () => {
    setMouseIn(false)

    setFirstTimeout(setTimeout(() => {
      setAnimation(false) 
      setSecondTimeout(setTimeout(() => {
        setPlayAnimation(false)
        setAnimation(true) 
      }, 1000))
    }, 1000))
  }

  const mouseEnterHandler = () => {
     setMouseIn(true)
     setPlayAnimation(true)
    if(!animation && secondTimeout) {
      setAnimation(true)
      clearTimeout(secondTimeout)
      clearTimeout(firstTimeout)
    }

     if (firstTimeout) {
      clearTimeout(firstTimeout)
      setFirstTimeout(null)
    }

    if (secondTimeout) {
      clearTimeout(secondTimeout)
      setSecondTimeout(null)
    } 
    }

  return (
    <PortfolioItemWrapper to={url !== '/' ? url + el.slug : el.slug}>
      <PortfolioItemImageWrapper
        mouseIn={mouseIn}
        animation={animation}
        playAnimation={playAnimation}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={() => mouseLeaveHandler()}
        onMouseMove={setCordsHandler}
      >
        <PortfolioItemImageContent mouseIn={playAnimation}>
          <PortfolioItemImage {...el.coverImage} />
        </PortfolioItemImageContent>
        <PortfolioItemImageCursor mouseIn={mouseIn} cords={cords} />
      </PortfolioItemImageWrapper>
      {el.title && <PortfolioItemTitle>{el.title}</PortfolioItemTitle>}
    </PortfolioItemWrapper>
  )
}
