import styled from '@emotion/styled'

const MapPopupItemText = styled.div`
  line-height: 20px;
  font-family: ${({theme}) => theme.typography.fontFamilyOrbitron};
  color: ${({theme}) => theme.palette.black[700]};

  ${({theme}) => theme.breakpoints.down('md')} {
    font-size: 14px;
  }

  ${({theme}) => theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`

export default MapPopupItemText
