import * as React from 'react'

const PhoneIcon = () => {
  return (
      <svg width="100%" height="100%" color="currentColor" transition=".5s" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.8167 21.2377C8.66748 21.6565 1.85415 11.2831 2.80077 6.22175C3.66931 4.7293 4.78257 3.62391 6.27252 2.75L9.51304 7.20123L7.86965 10.0553C7.86965 10.0553 8.32344 11.9607 10.0314 13.6686C11.824 15.4612 13.8236 16.0093 13.8236 16.0093L16.6777 14.3659L21.2885 17.7659C20.4307 19.2985 19.3492 20.3799 17.8167 21.2377Z" stroke="#004CF2" strokeWidth="1.5" strokeLinecap="square"/>
</svg>

  )
}

export default PhoneIcon
