import * as React from 'react'

const MailIcon = () => {
  return (
    <svg width="100%" height="100%" color="currentColor" transition=".5s" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.8037 8.85107L12.0395 13.535L6.27539 8.85107" stroke="#004CF2" strokeWidth="1.5" strokeLinecap="square"/>
    <rect x="2" y="3" width="20" height="18" stroke="#004CF2" strokeWidth="1.5" strokeLinecap="square"/>
    </svg>
    
  )
}

export default MailIcon
