import styled from '@emotion/styled'

const GalleryTabsStackItem = styled.button`
  line-height: 1;
  max-width: 50%;
  padding: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default GalleryTabsStackItem
