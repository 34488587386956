import * as React from 'react'

const TwitterIcon = () => {
  return (
    <svg viewBox="0 0 21 20" width="100%" height="100%" color="currentColor" transition=".5s">
      <path
        fill="currentColor"
        d="M19.6855 6.4575C19.6855 6.27667 19.6785 6.10083 19.6698 5.9275C20.5201 5.35 21.2354 4.62917 21.8182 3.79917V3.79833C21.0402 4.12417 20.2126 4.34083 19.3483 4.44583C20.237 3.93833 20.9156 3.14083 21.2345 2.18C20.406 2.6525 19.4912 2.98583 18.5163 3.1725C17.7296 2.37083 16.6084 1.875 15.3852 1.875C13.012 1.875 11.1014 3.7175 11.1014 5.97583C11.1014 6.30083 11.1302 6.61333 11.2008 6.91083C7.63749 6.74417 4.4837 5.11083 2.36492 2.62167C1.99552 3.23583 1.77772 3.93833 1.77772 4.69417C1.77772 6.11417 2.54177 7.3725 3.68219 8.10167C2.99306 8.08917 2.31787 7.8975 1.74548 7.59667V7.64167C1.74548 9.63417 3.2309 11.2892 5.1798 11.6708C4.52465 11.8425 3.71791 11.8358 3.24484 11.7358C3.7998 13.3592 5.3767 14.5533 7.2498 14.5925C5.79139 15.6833 3.9392 16.3408 1.93454 16.3408C1.58344 16.3408 1.24628 16.3258 0.909119 16.285C2.80836 17.4558 5.05783 18.125 7.48503 18.125C15.373 18.125 19.6855 11.875 19.6855 6.4575Z"
      />
    </svg>
  )
}

export default TwitterIcon
