import styled from '@emotion/styled'
import Link from '../Link'

const MapPopupItem = styled(Link)`
  display: flex;
  align-items: center;
  gap: 6px;
`

export default MapPopupItem
