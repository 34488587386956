import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Link from '../Link'

const dynamicStyles = ({ theme, inView }) => css`
  color: ${inView ? theme.palette.orange[400] : 'inherit'};
`

const SpyLinkItem = styled(Link)`
  
  letter-spacing: 1px;
  text-transform: uppercase;
  height: min-content;
  outline: 0px solid transparent !important;
  border-bottom: none;
  margin-top: 0px;
  width: 100%;
  transition: 0.3s;

  ${({theme}) => theme.breakpoints.down('xl')} {
    padding: 16px 14px;
  }

  ${({theme}) => theme.breakpoints.between('xl', '2xl')} {
    padding: 16px 10px;
  }

  ${({theme}) => theme.breakpoints.up('2xl')} {
    padding: 16px 14px;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.orange[50]};
  }

  ${dynamicStyles}
`

export default SpyLinkItem
