import styled from '@emotion/styled'

const MapPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  height: 100%;

  ${({theme}) => theme.breakpoints.down('sm')} {
    width: 280px;
  }

  ${({ theme }) => theme.breakpoints.between('sm', 'md')} {
    width: 380px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 10px;
    gap: 10px;
  }

  ${({theme}) => theme.breakpoints.up('md')} {
    width: 456px;
  }

  ${({ theme }) => theme.breakpoints.between('md', 'xl')} {
    padding: 15px;
    gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding: 20px;
    gap: 20px;
  }
`

export default MapPopupWrapper
