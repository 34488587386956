import styled from '@emotion/styled'
import Image from '../Image'

const MapPopupHeadingLogo = styled(Image)`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 99px;
    height: 21px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 128px;
  }
`

export default MapPopupHeadingLogo
