import styled from '@emotion/styled'
import { css } from '@emotion/react'

const dynamicStyle = ({ active }) => css`
  &::after {
    ${active ? 'opacity : 1' : 'opacity: 0'}
  }
`

const GalleryTabsStackItemTitle = styled.span`
  position: relative;
  letter-spacing: 2px;
  text-transform: uppercase;
  white-space: nowrap;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    bottom: -10px;
    left: 0;
    height: 4px;
    background-color: ${({ theme }) => theme.palette.orange[400]};
    transition: 0.5s;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 12px;
  }

  ${({ theme }) => theme.breakpoints.between('sm', 'md')} {
    font-size: 16px;
  }

  ${({ theme }) => theme.breakpoints.between('md', 'lg')} {
    font-size: 20px;
  }

  ${({ theme }) => theme.breakpoints.between('lg', 'xl')} {
    font-size: 26px;
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    font-size: 40px;
  }

  ${dynamicStyle}
`

export default GalleryTabsStackItemTitle
