import styled from '@emotion/styled'

const MapMarkerImage = styled.img`
  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 45px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 70px;
  }
`

export default MapMarkerImage
